
import Vue, { PropType } from 'vue';
import HeatMap from '@/components/analytics/charts/HeatMap/HeatMap.vue';
import { HeatMapColumn, HeatMapRow, HeatMapRow as HeatMapRowType } from '@/components/analytics/charts/HeatMap/types';
import GradeValueSmile from '@/components/analytics/charts/GradeValueSmile.vue';

export default Vue.extend({
  name: 'PdfHeatMapWidget',

  components: {
    HeatMap,
    GradeValueSmile,
  },

  props: {
    rows: {
      type: Array as PropType<HeatMapRowType[]>,
      default: () => ([]),
    },
    columns: {
      type: Array as PropType<HeatMapColumn[]>,
      default: () => ([]),
    },
    minRating: {
      type: Number,
      default: 1,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    scrollableColumns(): HeatMapColumn[] {
      return this.columns.filter((col) => !col.isFixed);
    },
  },
  methods: {
    isTextGroupColumn(columnName: string, value: string | number | null) {
      if (columnName.indexOf('group-') < 0) {
        return false;
      }
      return typeof value === 'string';
    },
    averageIsEnabled(item: HeatMapRow) {
      return !item.averageOptions || (item.averageOptions.display && !item.averageOptions.anonymityThresholdReached);
    },
  },

});
